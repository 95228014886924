import CardListSection, { CardListSectionModel } from "@/app/(marketing)/_components/_sections/CardListSection";
import CtaSectionComponent from "@/app/(marketing)/_components/_sections/CtaSectionComponent";
import HorizontalContentSectionComponent, { HorizontalContentSectionModel } from "@/app/(marketing)/_components/_sections/HorizontalContentSectionComponent";
import HorizontalHeroSectionComponent, { HorizontalHeroSectionModel } from "@/app/(marketing)/_components/_sections/HorizontalHeroSectionComponent";
import NumberHookSectionComponent, { NumberHookSectionModel } from "@/app/(marketing)/_components/_sections/NumberHookSectionComponent";
import QuoteSectionComponent, { QuoteSectionSectionModel } from "@/app/(marketing)/_components/_sections/QuoteSectionComponent";
import SocialProofSectionComponent, { SocialProofSectionModel } from "@/app/(marketing)/_components/_sections/SocialProofSectionComponent";
import SplitVerticalNarrativeSectionComponent, { SplitVerticalNarrativeSectionModel } from "@/app/(marketing)/_components/_sections/SplitVerticalNarrativeSection";
import SubscribeToMarketReportsSection, { SubscribeToMarketReportsModel } from "@/app/(marketing)/_components/_sections/_subsribeToMarketReports/SubscribeToMarketReportsSections";
import SubscriptionPriceSection, { SubscriptionPriceSectionModel } from "@/app/(marketing)/_components/_sections/SubscriptionPriceSection";
import TeamOverviewSectionComponent, { TeamOverviewSectionModel } from "@/app/(marketing)/_components/_sections/TeamOverviewSection";
import TextSection, { TextSectionModel } from "@/app/(marketing)/_components/_sections/TextSection";
import TextWithHeaderSection, { TextWithHeaderSectionModel } from "@/app/(marketing)/_components/_sections/TextWithHeaderSection";
import TitleSectionComponent, { TitleSectionModel } from "@/app/(marketing)/_components/_sections/TitleSectionComponent";
import VerticalHeroSectionComponent, { VerticalHeroSectionModel } from "@/app/(marketing)/_components/_sections/VerticalHeroSectionComponent";
import VerticalListSectionComponent, { VerticalListSectionModel } from "@/app/(marketing)/_components/_sections/VerticalListSectionComponent";
import MediaSection, { MediaSectionModel } from "@/app/(marketing)/_components/_sections/MediaSection";

export interface FeaturePageLinkModel {
    title: string;
    slug: string;
}

export interface ButtonModel {
    sys: {
        id: string;
    };
    __typename: string;
    title: string;
    linkPage: FeaturePageLinkModel  | undefined;
    action: "Go To Home" | "Go To Feature Page" | "Go To Sign Up" | "Go To Sign In" | "External Link";
    externalLink: string | undefined;
}

export interface CtaSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    title: string | undefined;
    paragraph: ContentfulRichTextModel | undefined;
    primaryCta: ButtonModel | undefined;
    secondaryCta: ButtonModel | undefined;
    hookSubtitleText: ContentfulRichTextModel | undefined;
    colorScheme: "Racing Green" | "Sand" | "Dark" | "Light Green" | undefined;
}


export type SectionModel =
    HorizontalContentSectionModel
    | SocialProofSectionModel
    | VerticalHeroSectionModel
    | QuoteSectionSectionModel
    | HorizontalHeroSectionModel
    | TitleSectionModel
    | VerticalListSectionModel
    | CtaSectionModel
    | NumberHookSectionModel
    | SplitVerticalNarrativeSectionModel
    | TeamOverviewSectionModel
    | SubscriptionPriceSectionModel
    | TextWithHeaderSectionModel
    | TextSectionModel
    | CardListSectionModel
    | SubscribeToMarketReportsModel
    | MediaSectionModel;

export interface ComponentMap {
        [key: string]: React.FC<any> | undefined;
    }

export const componentMapping: ComponentMap = {
        'CtaSection': CtaSectionComponent,
        'DefaultValuePropSection': HorizontalContentSectionComponent,
        'HorizontalHeroSection': HorizontalHeroSectionComponent,
        'NumberHookSection': NumberHookSectionComponent,
        'QuoteSection': QuoteSectionComponent,
        'SocialProofSection': SocialProofSectionComponent,
        'SplitVerticalNarrativeSection': SplitVerticalNarrativeSectionComponent,
        'TeamOverviewSection': TeamOverviewSectionComponent,
        'TitleSection': TitleSectionComponent,
        'LargeHeroSection': VerticalHeroSectionComponent,
        'VerticalListSection': VerticalListSectionComponent,
        'SubscriptionPriceSection': SubscriptionPriceSection,
        'TextWithHeaderSection': TextWithHeaderSection,
        'TextSection': TextSection,
        'CardListSection': CardListSection,
        'SubscribeToMarketReportsSection': SubscribeToMarketReportsSection,
        'MediaSection': MediaSection,
    };
