"use client"

import { Checkbox } from "@/components/ui/checkbox"
import { cn } from "@/lib/utils"
import { useFormStatus } from "react-dom"

interface STMRCheckboxProps {
    id: string
    name: string
    label: string
    state: {
        usexportreport?: string[] | undefined;
        euimportexportreport?: string[] | undefined;
        basicmarketreport?: string[] | undefined;
    } | undefined
}

export default function STMRCheckboxComponent({ id, name, label, state }: STMRCheckboxProps) {
    const { pending } = useFormStatus()
    
    return (
        <div className="flex flex-row">
            <Checkbox
                disabled={pending}
                id={id}
                name={name}
                className={cn("w-[32px] h-[32px] rounded-[8px] data-[state=checked]:bg-primary-200 data-[state=checked]:text-primary-900",
                    (state?.usexportreport || state?.basicmarketreport || state?.euimportexportreport) && "border-[#FF8364] border-2")} />
            <label
                htmlFor={id}
                className="pl-4 text-primary-900 font-recoleta font-light text-[20px]"
            >
                {label} 
            </label>
        </div>
    )
}