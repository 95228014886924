import { Markdown } from "../../markdown";
import { ContentfulLivePreview } from "@contentful/live-preview";
import Link from "next/link";
import { useFormState, useFormStatus } from "react-dom";
import { subscribeToReports } from "@/app/app-api/reportssubscription";
import STMRSubmitButtonComponent from "./STMRButtonComponent";
import STMRSmallCheckboxComponent from "./STMRSmallCheckboxComponent";
import STMREmailFieldComponent from "./STMREmailFieldComponent";
import STMRCheckboxComponent from "./STMRCheckboxComponent";
import { Paragraph } from "@/components/ui/texts/paragraph";
import { Heading } from "@/components/ui/Heading";


export interface SubscribeToMarketReportsModel {
    sys: {
        id: string;
    };
    __typename: string;
    headlineText: ContentfulRichTextModel;
    paragraphText: ContentfulRichTextModel;
}


const SubscribeToMarketReportsSection: React.FC<SubscribeToMarketReportsModel> = (SubscribeToMarketReportsModel) => {

    const initialState = {
        statusCode: undefined,
        fieldErrors: {
            email: undefined,
            usexportreport: undefined,
            euimportexportreport: undefined,
            basicmarketreport: undefined,
        }
    }

    const [state, formAction] = useFormState(subscribeToReports, initialState)

    let { pending } = useFormStatus()

    return (
        <div
            className="cta_section flex flex-col items-center w-full bg-[#E2F6B9]">
            <div className="z-10 max-w-[1440pt] w-full flex flex-col items-center py-10 px-6 lg:px-[155px] text-background">
                {state.statusCode === 200 && (
                    <div className="flex flex-col items-center">
                        <Heading styleVariant={"smallHeadline"} typeVariant={"h3"} className="text-primary-900">Subscription successful</Heading>
                        <Paragraph className="text-primary-900 mt-6 lg:max-w-[600px] text-center">
                            Thank you for subscribing to our market reports. You will receive an email with the requested reports shortly.
                        </Paragraph>
                    </div>

                ) || (
                        <>
                            {SubscribeToMarketReportsModel.headlineText &&
                            <div
                                {...ContentfulLivePreview.getProps({
                                    entryId: SubscribeToMarketReportsModel.sys.id,
                                    fieldId: "headlineText",
                                    locale: "en-US",
                                })}
                            >
                                <Markdown
                                    className="text-center text-gray-900"
                                    forcedBlock="heading-3"
                                    content={SubscribeToMarketReportsModel.headlineText} />
                            </div>

                            }
                            {SubscribeToMarketReportsModel.paragraphText &&
                                <div
                                    {...ContentfulLivePreview.getProps({
                                        entryId: SubscribeToMarketReportsModel.sys.id,
                                        fieldId: "paragraphText",
                                        locale: "en-US",
                                    })}
                                    className="pt-6">
                                    <Markdown
                                        className="text-center max-w-2xl text-gray-900"
                                        bodyVariant="body1"
                                        forcedBlock="paragraph"
                                        content={SubscribeToMarketReportsModel.paragraphText} />
                                </div>
                            }


                            <form className="mt-10" action={formAction}>
                                <h1 className="text-[50px] text-primary-900">{pending}</h1>

                                <div className="flex flex-col gap-4">
                                    <STMRCheckboxComponent id="usexportreport" name="usexportreport" label="US Export Sales Market Report" state={state.fieldErrors} />
                                    <STMRCheckboxComponent id="euimportexportreport" name="euimportexportreport" label="EU Import & Exports Market Report" state={state.fieldErrors} />
                                    <STMRCheckboxComponent id="basicmarketreport" name="basicmarketreport" label="Daily (Basic) Market Report" state={state.fieldErrors} />
                                    {(state?.fieldErrors?.usexportreport) && (
                                        <p className="font-roboto text-[12px] text-[#FF8364]"> {state.fieldErrors.usexportreport}</p>
                                    )}
                                </div>
                                <div className="flex flex-col lg:flex-row gap-6 mt-10">
                                    <div className="flex flex-col gap-2">
                                        <STMREmailFieldComponent id="email" name="email" placeholder="Enter your email" state={state.fieldErrors} />
                                        {state?.fieldErrors?.email && <p className="font-roboto text-[12px] text-[#FF8364]"> {state.fieldErrors.email}</p>}
                                    </div>
                                    {<STMRSubmitButtonComponent />}

                                </div>
{/* 
                                <div className="flex flex-row mt-4">
                                    <STMRSmallCheckboxComponent id="contactchecked" name="contactchecked" label="Please contact me via email" />
                                </div> */}

                                <p className="max-w-[350px] pt-4 text-primary-900 font-roboto font-light text-[12px]">
                                    By clicking Subscribe, I agree that I have read and accepted the <Link target="_blank" rel="noopener noreferrer" className="text-[#2C92DD] hover:underline" href="/terms">Terms of Use</Link>.
                                </p>
                            </form>


                        </>)}
            </div>

        </div>
    );
};

export default SubscribeToMarketReportsSection; 