"use client"

import { Loader } from "lucide-react"
import { useFormStatus } from "react-dom"

export default function STMRSubmitButtonComponent() {
    const { pending } = useFormStatus()

    return (
        <button 
            type="submit" 
            disabled={pending} 
            className="w-full lg:w-[160px] px-6 py-4 h-[56px] inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:bg-opacity-90 bg-primary-900 text-secondary-200 hover:bg-opacity-70">
            { pending && (
                <Loader className="w-8 h-8 text-primary-200 animate-spin"/>
            ) || (
                <>Subscribe</>
            )}
            
        </button>
    )
}