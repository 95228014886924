import { Heading } from "@/components/ui/Heading";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { ContentfulLivePreview } from "@contentful/live-preview";

export interface TeamOverviewSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    headline: string;
    teamMembersCollection: {
        items: PersonAvatarModel[];
    };
}

export interface PersonAvatarModel {
    sys: {
        id: string;
    };
    __typename: string;
    avatarImage: ContentfulImageModel;
    name: string;
    jobTitle: string;

}

const TeamOverviewSectionComponent: React.FC<TeamOverviewSectionModel> = (teamOverviewSectionModel) => {

    const layoutLookup: { [key: number]: number[] } = {
        1: [1],
        2: [2],
        3: [3],
        4: [4],
        5: [3, 2],
        6: [3, 2, 1],
        7: [4, 3],
        8: [3, 2, 3],
        9: [5, 4],
        10: [3, 4, 3],
        11: [4, 3, 4],
        12: [5, 4, 3],
        13: [4, 5, 4],
        14: [5, 4, 5],
    }

    if (!teamOverviewSectionModel || !teamOverviewSectionModel.teamMembersCollection || teamOverviewSectionModel.teamMembersCollection.items.length < 1) {
        return null;
    }

    const imageCount: number = teamOverviewSectionModel.teamMembersCollection.items.length ? teamOverviewSectionModel.teamMembersCollection.items.length : 0;

    let imageCounter = 0;

    return (
        <div className="flex flex-col w-full items-center bg-secondary-200 py-10">
            <Heading
                styleVariant={"subHeadline"}
                typeVariant={"h4"}
                {...ContentfulLivePreview.getProps({
                    entryId: teamOverviewSectionModel.sys.id,
                    fieldId: 'headline',
                    locale: 'en-US',
                })}
                className=" text-grey-900 ">{teamOverviewSectionModel.headline}</Heading>
            <div 
                {...ContentfulLivePreview.getProps({
                    entryId: teamOverviewSectionModel.sys.id,
                    fieldId: 'teamMembers',
                    locale: 'en-US',
                })}
                className="hidden lg:flex flex-col gap-10 pt-10">
                {imageCount > 0 && layoutLookup[imageCount].map((layout, index) => {
                    return (
                    <><div
                        key={layout + "-" + index}
                        className=" w-full text-black flex flex-row justify-center items-start gap-[80px] lg:gap-[110px] ">
                        {[...Array(layoutLookup[imageCount][index])].map((_, index) => {
                            imageCounter++;
                            const element = teamOverviewSectionModel.teamMembersCollection.items[imageCounter - 1];
                            return (
                                <li
                                    {...ContentfulLivePreview.getProps({
                                        entryId: element.sys.id,
                                        fieldId: 'personAvatar',
                                        locale: 'en-US',
                                    })}
                                    key={element.sys.id + "-" + index}
                                    className="flex flex-col justify-center items-center w-[160px] lg:w-[200px]">
                                    <img
                                        className="w-[96px] h-[96px] rounded-full aspect-[1/1] object-cover object-top"
                                        src={element.avatarImage.url} />
                                    <p className="font-roboto w-full text-[16px] text-center pt-4">{element.name}</p>
                                    {teamOverviewSectionModel.teamMembersCollection.items[imageCounter - 1].jobTitle && (
                                        <p className="font-roboto w-full text-[16px] text-center">{element.jobTitle}</p>
                                    )}
                                </li>)
                        })}
                    </div>

                    
                    </>)
                })}
            </div>
            <div className="lg:hidden flex flex-row w-full">
                <Carousel className="w-full pt-6">
                    <CarouselContent>
                        {teamOverviewSectionModel.teamMembersCollection.items.map((element, index) => (
                            <CarouselItem className="flex flex-col justify-start items-start w-full max-w-[240px] px-4">
                                <div className="w-full flex flex-col items-center justify-start">
                                    <img className="w-[96px] h-[96px] rounded-full aspect-[1/1] object-cover object-top" key={index} src={element.avatarImage.url} alt={`image-${index}`} />
                                    <p className="font-roboto w-full text-[18px] text-center text-grey-900 pt-4">{element.name}</p>
                                    <p className="font-roboto w-full text-[14px] text-center text-grey-900 opacity-80">{element.jobTitle}</p>

                                </div>
                                
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                </Carousel>


            </div>

        </div>
    )
};

export default TeamOverviewSectionComponent;