"use client"

import { Checkbox } from "@/components/ui/checkbox"
import { cn } from "@/lib/utils"
import { useFormStatus } from "react-dom"

interface STMREmailFieldProps {
    id: string
    name: string
    placeholder: string
    state: {
      email?: string[] | undefined;
  } | undefined
}

export default function STMREmailFieldComponent({ id, name, placeholder, state }: STMREmailFieldProps) {
    const { pending } = useFormStatus()

    return (
      <input 
        disabled={pending}
        id={id}
        className={cn("h-[56px] p-2 border w-[350px] rounded-[8px] outline-primary-900 border-primary-900 bg-primary-200 text-primary-900 placeholder-primary-900 placeholder-opacity-60 focus-visible:border-primary-900",
            state?.email && "border-[#FF8364] border-2")} 
        type="email"
        name={name}
        required
        placeholder={placeholder} />
    )
}